<template>
  <div class="login">
    <h1 class="title login__title">{{ $t('editpas.title') }}</h1>
    <p>{{$t('login.username')}}: <b>{{username}}</b></p>
    <template v-if="isValidSession">
      <template v-if="resetSuccess">
        <p class="success">{{ $t('editpas.success') }}</p>
        <p>{{ $t('editpas.back') }}
          <router-link to="/login">Log in</router-link>
        </p>
      </template>
      <template v-else>
        <label for="rpass">{{ $t('editpas.pass') }} ({{ $t('editpas.min') }})</label>
        <input id="rpass" type="password" class="input login__input" v-model="password"
               :placeholder="$t('editpas.pass')" autocomplete="new-password">
        <label for="rpass2">{{ $t('editpas.confirm') }}</label>
        <input id="rpass2" type="password" class="input login__input" v-model="password2"
               :placeholder="$t('editpas.confirm')" autocomplete="new-password">
        <button class="button" type="button"
                @click="submitReset">{{ $t('editpas.title') }}
        </button>
        <p class="error login__error"><span v-if="resetError">{{ $t(`editpas.${resetError}`) }}</span></p>
      </template>
    </template>
    <template v-else>
      <p class="error">{{ $t('editpas.errorvalid') }}</p>
      <p>{{ $t('editpas.back') }}
        <router-link to="/login">portal</router-link>
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",

  data() {
    return {
      isValidSession: true,
      username: this.$route.query.username,
      code: this.$route.query.code,
      expired: false,

      password: "",
      password2: "",

      resetError: false,
      resetSuccess: false,
    };
  },

  created() {
    if (!this.username || !this.code) {
      this.$router.replace('/login');
      return;
    }
    this.checkValidSession();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    async checkValidSession() {
      try{
        await this.$store.dispatch('user/checkValidReset',{username:this.username, code:this.code});
        this.isValidSession = true;
      }catch(e){
        this.isValidSession = false;
      }
    },

    async submitReset() {
      this.resetError = false;
      if (!this.password || !this.password2 || this.password !== this.password2 || this.password.toString().length < 8) {
        this.resetError = 'errorsame';
        return;
      }
      try{
        await this.$store.dispatch('user/resetPassword',{username:this.username, password:this.password, code:this.code});
        this.resetSuccess = true;
      }catch(e){
        this.resetError = 'error';
      }
    }
  }
}
</script>